var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "introduce-box" },
    _vm._l(_vm.introduceList, function (item, idx) {
      return _c(
        "div",
        {
          key: item.title,
          staticClass: "introduce-item",
          style: {
            flexDirection: !_vm.direction
              ? idx % 2 !== 1
                ? "row"
                : "row-reverse"
              : idx % 2 === 1
              ? "row"
              : "row-reverse",
          },
        },
        [
          _c("img", { attrs: { src: item.img, alt: "" } }),
          _c(
            "div",
            {
              staticClass: "introduce-info",
              style: {
                marginLeft:
                  !_vm.direction && idx % 2 !== 1
                    ? "100px"
                    : _vm.direction && idx % 2 === 1
                    ? "100px"
                    : "",
                marginRight:
                  !_vm.direction && idx % 2 === 1
                    ? "100px"
                    : _vm.direction && idx % 2 !== 1
                    ? "100px"
                    : "",
              },
            },
            [
              _vm.language === "zh"
                ? _c(
                    "div",
                    {
                      staticClass: "title",
                      style: {
                        textAlign: !_vm.direction
                          ? idx % 2 !== 1
                            ? ""
                            : "right"
                          : idx % 2 === 1
                          ? ""
                          : "right",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t(item.title)))]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "title",
                      style: {
                        textAlign: !_vm.direction
                          ? idx % 2 !== 1
                            ? ""
                            : "right"
                          : idx % 2 === 1
                          ? ""
                          : "right",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t(item.title_en)))]
                  ),
              _vm.language === "zh"
                ? _c("p", { staticStyle: { margin: "0" } }, [
                    _vm._v(_vm._s(_vm.$t(item.content))),
                  ])
                : _c("p", { staticStyle: { margin: "0" } }, [
                    _vm._v(_vm._s(_vm.$t(item.content_en))),
                  ]),
              _c("img", {
                style: {
                  marginLeft: idx % 2 !== 1 || _vm.imgRight ? "" : "174px",
                  width: item.imgWidth ? item.imgWidth : "",
                },
                attrs: { src: item.contImg, alt: "" },
              }),
              item.subContent.length
                ? _c(
                    "div",
                    {
                      staticClass: "sub-contnet",
                      style: {
                        textAlign:
                          _vm.direction && idx % 2 !== 1 ? "right" : "",
                      },
                    },
                    _vm._l(item.subContent, function (subContent) {
                      return _c(
                        "span",
                        {
                          key: subContent.index,
                          staticStyle: {
                            display: "inline-block",
                            width: "100%",
                          },
                        },
                        [
                          _vm.language === "zh" && subContent.cn !== ""
                            ? _c("span", [
                                _vm._v(" " + _vm._s(subContent.cn) + " "),
                              ])
                            : _vm._e(),
                          _vm.language === "en" && subContent.en !== ""
                            ? _c("span", [
                                _vm._v(" " + _vm._s(subContent.en) + " "),
                              ])
                            : _vm._e(),
                          _c("br"),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }