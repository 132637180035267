<template>
  <page>
    <div class="home-page">
      <zui-header :hasLine="true"/>
      <div class="service" style="padding: 0; margin-top: 77px">
        <div class="frist-m3-area">
          <div class="frist-m3-area frist-m3-area-top">
            <img class="banner-img" src="https://www.achencybe.com/img/pc/x3/X3-1-1.jpg"/>
            <div class="frist-m3-area-sub-title">
              <div style="margin-bottom: 6px">
                <span v-if="this.$i18n.locale==='zh'">{{ car_series_details.suitable }}</span>
                <span v-else>{{ car_series_details.suitable_en }}</span>
              </div>
              <div>
                <span v-if="this.$i18n.locale==='zh'">{{ car_series_details.type }}</span>
                <span v-else>{{ car_series_details.type_en }}</span>
              </div>
            </div>
            <div class="frist-m3-area-title">
              <div>
                <span v-if="this.$i18n.locale==='zh'">{{ car_series_details.slogan }}</span>
                <span v-else>{{ car_series_details.slogan_en }}</span>
              </div>
            </div>
            <div class="frist-m3-area-buy-area">
              <div class="frist-m3-area-buy-button" @click="goToBuy()">
                {{ $t('car_list.buy_now') }}
              </div>
            </div>
          </div>
          <div
              class="frist-m3-area frist-m3-area-show"
              style="padding-top: 0; background-color: #0c0c0c"
          >
            <img
                class="frist-m3-area-top-bottom-more"
                src="https://www.achencybe.com/img/pc/others/LearnMore.png"
                alt=""
            />
            <div class="frist-m3-area-show-area-div">
              <!--              <img src="../../../assets/images/other/M3_M4_S.png" style="width:80%;margin:110px 0 50px">-->
              <div class="frist-m3-area-show-area-div-top-box">
                <div class="logo-box">
                  <img class="logo" src="https://www.achencybe.com/img/pc/logo.png"/>
                  <div class="line"></div>
                </div>
                <div style="text-align: left; font-size: 18px">
                  <p v-if="this.$i18n.locale==='zh'">{{ car_series_details.technology_1 }}</p>
                  <p v-else>{{ car_series_details.technology_1_en }}</p>
                  <p v-if="this.$i18n.locale==='zh'">{{ car_series_details.technology_2 }}</p>
                  <p v-else>{{ car_series_details.technology_2_en }}</p>
                  <p v-if="this.$i18n.locale==='zh'">{{ car_series_details.technology_3 }}</p>
                  <p v-else>{{ car_series_details.technology_3_en }}</p>
                </div>
              </div>
              <img src="https://www.achencybe.com/img/pc/x3/X3-2-1.png" style="width: 60%; margin-bottom: 100px"/>
            </div>
          </div>
          <div class="frist-m3-area frist-m3-area-goodlist">
            <div class="features-box">
              <features
                  title="car_list.x3.show_page.technology_5"
                  :light="light"
                  :contains="contains"
                  :sub-title="subTitle"
                  :isThe3="true"
                  :language="this.$i18n.locale"
                  :isHidden="false"
                  :list="list"
              />
            </div>
            <div
                class="font-size-padding50 font-size-12"
                style="width: 100%; font-weight: 100"
            >
              {{$t('car_list.care_plus_remark_1')}}<br />
              {{$t('car_list.care_plus_remark_2')}}
            </div>
            <div class="banner-text">
              <div style="font-size: 55px"  v-if="this.$i18n.locale==='zh'">{{ car_series_details.show_page_slogan }}</div>
              <div style="font-size: 55px"  v-else>{{ car_series_details.show_page_slogan_en }}</div>
              <div style="font-size: 16px;max-width: 900px;margin:0 auto;">
                &nbsp;
              </div>
              <div style="font-size: 16px;max-width: 900px;margin:0 auto;" v-if="this.$i18n.locale==='zh'">
                {{ car_series_details.show_page_sub_slogan }}
              </div>
              <div style="font-size: 16px;max-width: 900px;margin:0 auto;" v-else>
                {{ car_series_details.show_page_sub_slogan_en }}
              </div>
            </div>
            <div class="carousel-box">
              <div class="left" @click="$refs.carousel.prev()">
                <img src="https://www.achencybe.com/img/pc/detail/challenger/left.png"/>
              </div>
              <el-carousel
                  :interval="5000"
                  arrow="never"
                  :height="bannerHeight"
                  ref="carousel"
              >
                <el-carousel-item v-for="item in 5" :key="item">
                  <img
                      :src="'https://www.achencybe.com/img/pc/x3/X3-4-' + item + '.jpg'"
                      alt=""
                  />
                </el-carousel-item>
              </el-carousel>
              <div class="right" @click="$refs.carousel.next()">
                <img src="https://www.achencybe.com/img/pc/detail/challenger/right.png" alt=""/>
              </div>
            </div>
            <div style="background-color: #0c0c0c; padding-top: 150px">
              <introduce imgRight
                         :introduceList="introduceList"
                         :language="this.$i18n.locale"
                         :direction="'right'"/>
              <div
                  style="
                  max-width: 1480px;
                  width: 100%;
                  margin-top: 200px;
                  margin: 0 auto;
                "
              >
                <div
                    style="
                    font-size: 54px;
                    text-align: left;
                    width: 1200px;
                    margin: 0 auto;
                  "
                >
                  DESIGNED BY ACHEN&CYBE STUDIO
                </div>
                <div
                    style="
                    font-size: 54px;
                    text-align: left;
                    width: 1200px;
                    margin: -18px auto 0;
                  "
                >
                  独有原创设计
                </div>
                <div
                    style="
                    font-size: 14px;
                    text-align: left;
                    width: 1200px;
                    margin: 0 auto;
                  "
                >
                  由Achen&Cybe Studio提供
                </div>
                <img
                    src="https://www.achencybe.com/img/pc/x3/X3-6-1.png"
                    class="frist-m3-area-goodlist-image-area"
                />
              </div>

              <detail-footer

                  title="BMW THE X3 LCI"
                  slogan="car_list.x3.footer.slogan"
                  :img="'https://www.achencybe.com/img/pc/x3/X3-8-1.png'"
                  @buy="goToBuy()"
              />
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
      <zui-footer/>
    </div>
  </page>
</template>
<script>
import "../../less/home-page.less";
import "../../less/m3-show.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import features from "@/components/features/template";
import introduce from "@/components/introduce/template";
import DetailFooter from "@/components/detail-footer";

export default {
  components: {Page, ZuiHeader, ZuiFooter, features, introduce, DetailFooter},
  data() {
    return {
      bannerHeight: "1050px",
      list: [],
      light: {},
      contains: {},
      car_series_details: {
        suitable: '',
        suitable_en: '',
        type: '',
        type_en: '',
        slogan: '',
        slogan_en: '',
        technology_1: '',
        technology_1_en: '',
        technology_2: '',
        technology_2_en: '',
        technology_3: '',
        technology_3_en: '',
        show_page_slogan: '',
        show_page_slogan_en: '',
        show_page_sub_slogan: '',
        show_page_sub_slogan_en: '',
      },
      subTitle: [
        {
          en: '',
          ch: '',
        },
      ],
      introduceList: [],
    };
  },
  mounted() {
    this.height = window.innerHeight + "px";
    this.bannerHeight = (651 / 1280) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
    window.addEventListener("resize", () => {
      this.$nextTick(() => {
        this.height = window.innerHeight - 120 + "px";
        this.bannerHeight = (651 / 1280) * (window.innerWidth >= 1200 ? window.innerWidth : 1200) + "px";
      });
    });
    this.getCarDetail();
  },
  methods: {
    goToBuy: function () {
      this.$router.push({
        path: "/pages/public/TemplateOrderPage",
        query: {cartype: "carlistX3"},
      });
    },
    getCarDetail() {
      this.$api
          .getCarDetailAndTranslatorsByName({
            carModelName: "THE X3",
          })
          .then((res) => {
            if (res.code === 0) {
              res.data.listAccessoriesList.sort((a, b) => {
                return a.partsIndex - b.partsIndex;
              });
              let inner_lit = []
              inner_lit = res.data.listAccessoriesList.filter(item => item.accessoriesListDetailsTranslators != null).map((item) => {
                console.log(item)
                return {
                  index: item.accessoriesListDetailsTranslators.accessoriesListIndex,
                  img: item.accessoriesListDetailsTranslators.accessoriesListPicture,
                  title: item.accessoriesListDetailsTranslators.accessoriesListTitle1,
                  title_en: item.accessoriesListDetailsTranslators.accessoriesListTitle1En,
                  year: item.accessoriesListDetailsTranslators.accessoriesListTitle2,
                  year_en: item.accessoriesListDetailsTranslators.accessoriesListTitle2En,
                  content: item.accessoriesListDetailsTranslators.accessoriesListMainBody,
                  content_en: item.accessoriesListDetailsTranslators.accessoriesListMainBodyEn,
                  price: item.partsPrice,
                  price_dollar: item.partsPriceDollar,
                }
              })
              this.list = inner_lit
              if (res.data.carSeriesTranslators) {
                let data_translators = res.data.carSeriesTranslators
                // 处理 车系介绍问题
                this.introduceList = [
                  {
                    img: data_translators.carSeriesIntroducePicture1,
                    title: data_translators.carSeriesIntroduceTitle1,
                    title_en: data_translators.carSeriesIntroduceTitle1En,
                    content: data_translators.carSeriesIntroduceMainBody1,
                    content_en: data_translators.carSeriesIntroduceMainBody1En,
                    contImg: "",
                    subContent: [
                      {
                        en: data_translators.carSeriesIntroduceEndingOne1En,
                        cn: data_translators.carSeriesIntroduceEndingOne1,
                      },
                      {
                        en: data_translators.carSeriesIntroduceEndingTwo1En,
                        cn: data_translators.carSeriesIntroduceEndingTwo1,
                      }
                    ],
                  },
                  {
                    img: data_translators.carSeriesIntroducePicture2,
                    title: data_translators.carSeriesIntroduceTitle2,
                    title_en: data_translators.carSeriesIntroduceTitle2En,
                    content: data_translators.carSeriesIntroduceMainBody2,
                    content_en: data_translators.carSeriesIntroduceMainBody2En,
                    contImg: "",
                    subContent: [
                      {
                        en: data_translators.carSeriesIntroduceEndingOne2En,
                        cn: data_translators.carSeriesIntroduceEndingOne2,
                      },
                      {
                        en: data_translators.carSeriesIntroduceEndingTwo2En,
                        cn: data_translators.carSeriesIntroduceEndingTwo2,
                      }
                    ],
                  },
                  {
                    img: data_translators.carSeriesIntroducePicture3,
                    title: data_translators.carSeriesIntroduceTitle3,
                    title_en: data_translators.carSeriesIntroduceTitle3En,
                    content: data_translators.carSeriesIntroduceMainBody3,
                    content_en: data_translators.carSeriesIntroduceMainBody3En,
                    contImg: "",
                    subContent: [
                      {
                        en: data_translators.carSeriesIntroduceEndingOne3En,
                        cn: data_translators.carSeriesIntroduceEndingOne3,
                      },
                      {
                        en: data_translators.carSeriesIntroduceEndingTwo3En,
                        cn: data_translators.carSeriesIntroduceEndingTwo3,
                      }
                    ],
                  },
                  {
                    img: data_translators.carSeriesIntroducePicture4,
                    title: data_translators.carSeriesIntroduceTitle4,
                    title_en: data_translators.carSeriesIntroduceTitle4En,
                    content: data_translators.carSeriesIntroduceMainBody4,
                    content_en: data_translators.carSeriesIntroduceMainBody4En,
                    contImg: "",
                    subContent: [
                      {
                        en: data_translators.carSeriesIntroduceEndingOne4En,
                        cn: data_translators.carSeriesIntroduceEndingOne4,
                      },
                      {
                        en: data_translators.carSeriesIntroduceEndingTwo4En,
                        cn: data_translators.carSeriesIntroduceEndingTwo4,
                      }
                    ],
                  }
                ]
                this.car_series_details.suitable = data_translators.carSeriesTitle1
                this.car_series_details.suitable_en = data_translators.carSeriesTitle1En
                this.car_series_details.type = data_translators.carSeriesTitle2
                this.car_series_details.type_en = data_translators.carSeriesTitle2En
                this.car_series_details.slogan = data_translators.carSeriesTitle3
                this.car_series_details.slogan_en = data_translators.carSeriesTitle3En
                this.car_series_details.technology_1 = data_translators.carSeriesIntroduction1
                this.car_series_details.technology_1_en = data_translators.carSeriesIntroduction1En
                this.car_series_details.technology_2 = data_translators.carSeriesIntroduction2
                this.car_series_details.technology_2_en = data_translators.carSeriesIntroduction2En
                this.car_series_details.technology_3 = data_translators.carSeriesIntroduction3
                this.car_series_details.technology_3_en = data_translators.carSeriesIntroduction3En
                this.car_series_details.show_page_slogan = data_translators.carouselTitle
                this.car_series_details.show_page_slogan_en = data_translators.carouselTitleEn
                this.car_series_details.show_page_sub_slogan = data_translators.carouselIntroduce
                this.car_series_details.show_page_sub_slogan_en = data_translators.carouselIntroduceEn
                this.light =
                    {
                      ch: data_translators.attachmentTitle1,
                      en: data_translators.attachmentTitle1En
                    }
                this.contains =
                    {
                      ch: data_translators.attachmentTitle2,
                      en: data_translators.attachmentTitle2En
                    }
                this.subTitle = [
                  {
                    ch: data_translators.attachmentIntroduce1,
                    en: data_translators.attachmentIntroduce1En
                  },
                  {
                    ch: data_translators.attachmentIntroduce2,
                    en: data_translators.attachmentIntroduce2En
                  },
                  {
                    ch: data_translators.attachmentIntroduce3,
                    en: data_translators.attachmentIntroduce3En
                  },
                ]
              }


            }
          })
          .catch((err) => {
            console.log("getCarDetail", err);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-box {
  justify-content: flex-end !important;

  .logo {
    width: 360px !important;
  }

  .line {
    margin: 0 100px !important;
  }
}
</style>
