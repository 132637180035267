<template>
  <div>
    <div class="top-box">
      <div v-if="light && language==='zh'">{{ light.ch }}</div>
      <div v-if="light && language==='en'">{{ light.en }}</div>
      <h1 style="margin: -5px 0" v-if="contains && language==='zh'">{{ contains.ch }}</h1>
      <h1 style="margin: -5px 0" v-if="contains && language==='en'">{{ contains.en }}</h1>

      <div class="sub-title" v-for="(item,idx) in subTitle" :key="idx">
        <div v-if="language=== 'zh'">{{ item.ch }} </div>
          <div v-else>{{ item.en }} </div>
      </div>
      <div class="list-con">
        <div class="list-item" v-for="(item, idx) in list" :key="idx">
          <img :src="item.img" alt=""/>
          <h2 style="white-space: nowrap" v-if="language=== 'zh'">
            {{ $t(item.title) }}
          </h2>
          <h2 style="white-space: nowrap" v-else>
            {{ $t(item.title_en) }}
          </h2>
          <div v-show="isThe3" style="font-weight: bold" v-if="language === 'zh'">{{ $t(item.year) }}</div>
          <div v-show="isThe3" style="font-weight: bold" v-else>{{ $t(item.year_en) }}</div>
          <div style="height:168px">
            <p :style="{ minHeight: idx === 0 || idx === 1 ? '0px' : '' }" v-if="language === 'zh'">
              {{ $t(item.content) }}
            </p>
            <p :style="{ minHeight: idx === 0 || idx === 1 ? '0px' : '' }" v-else>
              {{ $t(item.content_en) }}
            </p>
            <div style="font-weight: 100">{{ $t(item.subTitle) }}</div>
            <a
                :style="{textDecoration: 'underline',}"
                @click="$router.push({name:'CarEPlusPage'})"
            >
              {{ $t(item.a) }}
            </a>
          </div>


          <template v-if="item.price">
            <div class="price-box" :style="{ marginTop: item.a ? '' : '-2px' }">
              <span style="font-family: 'ELEMENT-ICONS'" v-if="language === 'zh'"> {{
                  item.price === 0 ? "待公告" : item.price === -1 ? "" : "RMB " + formatPrice(item.price)
                }}<span v-if="item.priceUnit"
                        style="font-size:20px;margin-left:2px;font-weight: bold">{{ item.priceUnit }}</span></span>

              <span style="font-family: 'ELEMENT-ICONS'" v-if="language !== 'zh'"> <span v-if="item.priceUnit"
                                                                                         style="font-size:20px;margin-left:2px;font-weight: bold">{{ "From " }}</span>{{
                  item.price_dollar === 0 ? "待公告" : item.price_dollar === -1 ? "" : "$ " + formatPrice(item.price_dollar)
                }}</span>

            </div>
          </template>
          <template v-else>
            <table style="margin-bottom:64px;width:100%;">
              <tr class="price-box" :style="{ marginTop: item.a ? '' : '-2px' }">
                <td style="width:50%;text-align: center" v-if="language=== 'zh'">{{ "RMB " }}<span
                    style="font-family: 'ELEMENT-ICONS'"> {{
                    item.price2 === 0 ? "待公告" : formatPrice(item.price2)
                  }}<span v-if="item.priceUnit"
                          style="font-size:20px;margin-left:2px;font-weight: bold">{{ item.priceUnit }}</span></span>
                </td>

                <td style="width:50%;text-align: center" v-else><span v-if="item.priceUnit"
                                                                      style="font-size:20px;margin-left:2px;font-weight: bold">{{ "From " }}</span>{{
                    "$ "
                  }}<span style="font-family: 'ELEMENT-ICONS'"> {{
                      item.price_dollar_2 === 0 ? "待公告" : formatPrice(item.price_dollar_2)
                    }}</span>
                </td>
              </tr>
            </table>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    title: String,
    light: Object,
    contains: Object,
    subTitle: Array,
    language: {
      type: String,
      default: 'zh'
    },
    topTitle: Array,
    list: {
      type: Array
    },
    isThe3: Boolean,
    isHidden: Boolean
  },
  methods: {
    formatPrice(val) {
      console.log(typeof val)
      if (val == null || val === 'null' || val === undefined || !val) {
        val = "0"
      }
      if (val) {
        return val.toString().replace(/\d+/, function (n) {
          return n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.top-box {
  padding-top: 40px;

  .sub-title {
    font-weight: 100;
  }

  h1 {
    font-size: 30px !important;
    margin: 12px 0 !important;
  }

  .list-con {
    margin-top: 75px;
    padding: 0 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .list-item {
      width: 49.5%;

      img {
        width: 100%;
        margin-bottom: 40px;
      }

      p {
        width: 100%;
        max-width: 370px;
        margin: 12px auto 0;
        min-height: 104px;
        font-weight: 100;
      }

      a {
        display: inline-block;
        min-width: 10px;
        height: 24px;
        line-height: 24px;
        margin-bottom: 20px;

        &:hover {
          color: #3d7bfe;
        }
      }

      .price-box {
        font-size: 26px;
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .top-box {
    .sub-title {
      width: 80%;
      font-size: 12px;
      margin: 0 auto;
    }

    .list-con {
      padding: 0;

      .list-item {
        p {
          width: 80%;
          line-height: 1.2;
          font-weight: 100;
          min-height: 104px;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .top-box {
    .sub-title {
      width: 80%;
      font-size: 12px;
      margin: 0 auto;
    }

    .list-con {
      padding: 0;

      .list-item {
        p {
          width: 80%;
          line-height: 1.2;
          font-weight: 100;
        }
      }
    }
  }
}
</style>