var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "top-box" },
      [
        _vm.light && _vm.language === "zh"
          ? _c("div", [_vm._v(_vm._s(_vm.light.ch))])
          : _vm._e(),
        _vm.light && _vm.language === "en"
          ? _c("div", [_vm._v(_vm._s(_vm.light.en))])
          : _vm._e(),
        _vm.contains && _vm.language === "zh"
          ? _c("h1", { staticStyle: { margin: "-5px 0" } }, [
              _vm._v(_vm._s(_vm.contains.ch)),
            ])
          : _vm._e(),
        _vm.contains && _vm.language === "en"
          ? _c("h1", { staticStyle: { margin: "-5px 0" } }, [
              _vm._v(_vm._s(_vm.contains.en)),
            ])
          : _vm._e(),
        _vm._l(_vm.subTitle, function (item, idx) {
          return _c("div", { key: idx, staticClass: "sub-title" }, [
            _vm.language === "zh"
              ? _c("div", [_vm._v(_vm._s(item.ch) + " ")])
              : _c("div", [_vm._v(_vm._s(item.en) + " ")]),
          ])
        }),
        _c(
          "div",
          { staticClass: "list-con" },
          _vm._l(_vm.list, function (item, idx) {
            return _c(
              "div",
              { key: idx, staticClass: "list-item" },
              [
                _c("img", { attrs: { src: item.img, alt: "" } }),
                _vm.language === "zh"
                  ? _c("h2", { staticStyle: { "white-space": "nowrap" } }, [
                      _vm._v(" " + _vm._s(_vm.$t(item.title)) + " "),
                    ])
                  : _c("h2", { staticStyle: { "white-space": "nowrap" } }, [
                      _vm._v(" " + _vm._s(_vm.$t(item.title_en)) + " "),
                    ]),
                _vm.language === "zh"
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isThe3,
                            expression: "isThe3",
                          },
                        ],
                        staticStyle: { "font-weight": "bold" },
                      },
                      [_vm._v(_vm._s(_vm.$t(item.year)))]
                    )
                  : _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isThe3,
                            expression: "isThe3",
                          },
                        ],
                        staticStyle: { "font-weight": "bold" },
                      },
                      [_vm._v(_vm._s(_vm.$t(item.year_en)))]
                    ),
                _c("div", { staticStyle: { height: "168px" } }, [
                  _vm.language === "zh"
                    ? _c(
                        "p",
                        {
                          style: {
                            minHeight: idx === 0 || idx === 1 ? "0px" : "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(item.content)) + " ")]
                      )
                    : _c(
                        "p",
                        {
                          style: {
                            minHeight: idx === 0 || idx === 1 ? "0px" : "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(item.content_en)) + " ")]
                      ),
                  _c("div", { staticStyle: { "font-weight": "100" } }, [
                    _vm._v(_vm._s(_vm.$t(item.subTitle))),
                  ]),
                  _c(
                    "a",
                    {
                      style: { textDecoration: "underline" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ name: "CarEPlusPage" })
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(item.a)) + " ")]
                  ),
                ]),
                item.price
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "price-box",
                          style: { marginTop: item.a ? "" : "-2px" },
                        },
                        [
                          _vm.language === "zh"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'ELEMENT-ICONS'",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.price === 0
                                          ? "待公告"
                                          : item.price === -1
                                          ? ""
                                          : "RMB " + _vm.formatPrice(item.price)
                                      )
                                  ),
                                  item.priceUnit
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "20px",
                                            "margin-left": "2px",
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.priceUnit))]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm.language !== "zh"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-family": "'ELEMENT-ICONS'",
                                  },
                                },
                                [
                                  item.priceUnit
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "20px",
                                            "margin-left": "2px",
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v(_vm._s("From "))]
                                      )
                                    : _vm._e(),
                                  _vm._v(
                                    _vm._s(
                                      item.price_dollar === 0
                                        ? "待公告"
                                        : item.price_dollar === -1
                                        ? ""
                                        : "$ " +
                                          _vm.formatPrice(item.price_dollar)
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "table",
                        {
                          staticStyle: {
                            "margin-bottom": "64px",
                            width: "100%",
                          },
                        },
                        [
                          _c(
                            "tr",
                            {
                              staticClass: "price-box",
                              style: { marginTop: item.a ? "" : "-2px" },
                            },
                            [
                              _vm.language === "zh"
                                ? _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        width: "50%",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s("RMB ")),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'ELEMENT-ICONS'",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.price2 === 0
                                                  ? "待公告"
                                                  : _vm.formatPrice(item.price2)
                                              )
                                          ),
                                          item.priceUnit
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "20px",
                                                    "margin-left": "2px",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.priceUnit))]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        width: "50%",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      item.priceUnit
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "20px",
                                                "margin-left": "2px",
                                                "font-weight": "bold",
                                              },
                                            },
                                            [_vm._v(_vm._s("From "))]
                                          )
                                        : _vm._e(),
                                      _vm._v(_vm._s("$ ")),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-family": "'ELEMENT-ICONS'",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.price_dollar_2 === 0
                                                  ? "待公告"
                                                  : _vm.formatPrice(
                                                      item.price_dollar_2
                                                    )
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                        ]
                      ),
                    ],
              ],
              2
            )
          }),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }